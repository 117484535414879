<template>
    <div class="main-container">
        <CommonHeader/>
        <LayoutMain/>
        <CommonFooter/>
    </div>
</template>

<script>
// @ is an alias to /src

import CommonHeader from "@/components/Header.vue";
import CommonFooter from "@/components/Footer.vue"
import LayoutMain from './LayoutMain'

export default {
    name:'Home',
    mounted() {
        this.$nextTick(function() {
        });
    },
    data(){
        return {
        }
    },
    methods: { //事件处理器
    },
    components: { //定义组件
        CommonHeader,
        CommonFooter,
        LayoutMain
    },
    created() { //生命周期函数
    },
    destroyed() {
    },
}
</script>

<style scoped>
.main-container{
    overflow: hidden;
}
</style>
