import router from './router'
import { scrollTo } from '@/utils/scrollTo'

router.beforeEach((to, from, next) => {

  scrollTo(0, 0)

  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = "广东世泊智能装备科技有限公司 - " + to.meta.title
  }
  
  next()
  
  
})