<template>
  <div class="Footer">
    <div class="main">
      <div class="l">
        <div class="image">
          <img src="@/assets/logo-black.png" />
        </div>
      </div>
      <div class="r">
        <div class="nav">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <div class="itemtab">
                <div class="tab-title">资讯</div>
                <div class="tab-item">
                  <div
                    v-for="(item, index) in tab_nav"
                    :key="index"
                    class="tab-item"
                  >
                    <router-link :to="{ name: item.toname }">
                      <div class="title">{{ item.title }}</div>
                    </router-link>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <div class="itemtab">
                <div class="tab-title">联系</div>
                <div class="tab-text">Email:</div>
                <div class="tab-text">4366153@qq.com</div>
                <div class="tab-text">QQ:</div>
                <div class="tab-text">3351912258</div>
                <div class="tab-text">Phone:</div>
                <div class="tab-text">020-31230097</div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="6">
              <div class="itemtab">
                <div class="tab-title">地址</div>
                <div class="tab-text">
                  广州市白云区(市内分部)同德街西槎路111号
                </div>
                <div class="tab-text">
                  广州市花都区(广州总部)新华街迎宾大道95号交通大厦904室
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="6" class="qrcode">
              <div class="tab-qrcode">
                <div class="itemtab">
                  <div class="ls">
                    <div class="qrcode-img">
                      <img alt="Vue logo" src="../assets/offceqrcode.png" />
                    </div>
                  </div>
                  <div class="rs">
                    <div class="qrcode-text">扫一扫 关注微信公众号</div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="CommonWidth">
        <div class="bottom-l bottom-text">© 2024 广东世泊智能装备科技有限公司 - ShiBoTech. All rights reserved.</div>
        <div class="bottom-r bottom-text"><a href="https://beian.miit.gov.cn/" target="_blank" style="color: #ffffff">粤ICP备18124638号-1</a></div>
        
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      tab_nav: [
        {
          title: "回到首页",
          toname: "",
        },
        {
          title: "产品",
          toname: "product",
        },
        {
          title: "解决方案",
          toname: "solution",
        },
        {
          title: "公司介绍",
          toname: "company",
        },
        {
          title: "新闻动态",
          toname: "dynamic",
        },
        {
          title: "招贤纳士",
          toname: "careers",
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.Footer {
  .main {
    width: 100%;
    display: flow-root;
    .l {
      width: 30%;
      min-height: 300px;
      float: left;
      background-color: #242424;
      display: flex;
      justify-content: center;
      line-height: 300px;
    }
    .r {
      width: 70%;
      min-height: 300px;
      float: right;
      background-color: #333333;
      display: flex;
      justify-content: left;

      .nav {
        margin-left: 6%;
        width: 80%;
        .itemtab {
          margin-top: 45px;
          margin-bottom: 30px;
        }

        .tab-title {
          font-size: 14px;
          color: #ffffff;
          font-weight: bold;
          width: 100%;
          margin-bottom: 20px;
        }

        .tab-item {
          margin-bottom: 6px;
          font-size: 12px;

          .title {
            color: #cacaca;
          }
        }

        .tab-text {
          width: 60%;
          margin-bottom: 6px;
          color: #cacaca;
          font-size: 12px;
        }

        .tab-qrcode {
          line-height: 80px;
          padding-bottom: 130px;

          .ls {
            width: 80px;
            float: left;
          }

          .rs {
            width: calc(100% - 90px);
            float: right;
          }

          .qrcode-img {
            width: 80px;
            height: 80px;

            img {
              width: 100%;
            }
          }

          .qrcode-text {
            font-size: 12px;
            color: #cacaca;
          }
        }
      }
    }
  }
  .bottom{
    line-height: 80px;
    min-height: 80px;
    color: #ffffff;
    background-color: #141414;
    .bottom-text{
      font-size: 14px;
      font-size: initial;
    }
    .bottom-l{
      float: left;
    }
    .bottom-r{
      float: right;
    }
  }
}

@media screen and (max-width: 1200px) {
  .Footer {
    .main {
      width: 100%;
      display: flow-root;
      .l {
        min-height: 600px;
        float: left;
        background-color: #242424;
        display: flex;
        justify-content: center;
        line-height: 600px;
      }
      .r {
        min-height: 600px;
        float: right;
        background-color: #333333;
        display: flex;
        justify-content: left;

        .nav {
          margin-left: 6%;
          width: 80%;
          .itemtab {
            margin-top: 45px;
            margin-bottom: 30px;
          }

          .tab-title {
            font-size: 14px;
            color: #ffffff;
            font-weight: bold;
            width: 100%;
            margin-bottom: 20px;
          }

          .tab-item {
            margin-bottom: 6px;
            font-size: 12px;

            .title {
              color: #cacaca;
            }
          }

          .tab-text {
            width: 60%;
            margin-bottom: 6px;
            color: #cacaca;
            font-size: 12px;
          }

          .tab-qrcode {
            line-height: 80px;
            padding-bottom: 130px;

            .ls {
              width: 80px;
              float: left;
            }

            .rs {
              width: calc(100% - 90px);
              float: right;
            }

            .qrcode-img {
              width: 80px;
              height: 80px;

              img {
                width: 100%;
              }
            }

            .qrcode-text {
              font-size: 12px;
              color: #cacaca;
            }
          }
        }
      }
    }
    .bottom{
      display: flow-root;
      .bottom-text{
        font-size: 14px;
        font-size: initial;
      }
      .bottom-l{
        float: left;
        width: 100%;
      }
      .bottom-r{
        float: right;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .Footer {
    .main {
      width: 100%;
      display: flow-root;
      .l {
        width: 100%;
        min-height: 300px;
        float: left;
        background-color: #242424;
        display: flex;
        justify-content: center;
        line-height: 300px;
      }
      .r {
        width: 100%;
        min-height: 300px;
        float: right;
        background-color: #333333;
        display: flex;
        justify-content: left;

        .nav {
          margin-left: 6%;
          width: 80%;
          .itemtab {
            margin-top: 45px;
            margin-bottom: 30px;
          }

          .tab-title {
            font-size: 14px;
            color: #ffffff;
            font-weight: bold;
            width: 100%;
            margin-bottom: 20px;
          }

          .tab-item {
            margin-bottom: 6px;
            font-size: 12px;

            .title {
              color: #cacaca;
            }
          }

          .tab-text {
            width: 60%;
            margin-bottom: 6px;
            color: #cacaca;
            font-size: 12px;
          }

          .tab-qrcode {
            line-height: 80px;
            padding-bottom: 130px;

            .ls {
              width: 80px;
              float: left;
            }

            .rs {
              width: calc(100% - 90px);
              float: right;
            }

            .qrcode-img {
              width: 80px;
              height: 80px;

              img {
                width: 100%;
              }
            }

            .qrcode-text {
              font-size: 12px;
              color: #cacaca;
            }
          }
        }
      }
    }
    .bottom{
      display: flow-root;
      .bottom-text{
        font-size: 14px;
        font-size: initial;
      }
      .bottom-l{
        float: left;
        width: 100%;
      }
      .bottom-r{
        float: right;
        width: 100%;
      }
    }
  }
}
</style>
