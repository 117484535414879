import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import '@/permission' // permission control
import '@/styles/main.scss' // global css
import VideoBg from 'vue-videobg'
import { VueEditor } from "vue2-editor";
import * as  bootstrap from '@/plugin/bootstrap.js'

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueEditor)
Vue.component('video-bg', VideoBg)

new Vue({
  bootstrap,
  router,
  store,
  components:{
    VideoBg
  },
  render: h => h(App)
}).$mount('#app')

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
  to, from, next
})