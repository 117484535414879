<template>
    <div class="ComponentsHeader">
        <div class="main">
            <div class="CommonWidth">
                <div class="logo">
                    <router-link
                        :to="{ name: 'home' }"
                        @click.native="oepnnav()"
                    >
                        <img
                            class="img"
                            alt="logo"
                            src="../assets/logo-hide.png"
                        />
                    </router-link>
                </div>

                <div class="openbutton" @click="oepnnav()">
                    <transition name="fade" mode="out-in" appear>
                        <button
                            class="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navmenu"
                        >
                            <span class="navbar-toggler-icon"></span>
                        </button>
                    </transition>
                </div>

                <div class="nav-main collapse" id="navmenu">
                    <div
                        v-for="(item, index) in nav_item"
                        :key="index"
                        class="nav-item"
                    >
                        <router-link
                            :to="{ name: item.toname }"
                            @click.native="oepnnav()"
                            exact
                        >
                            <transition name="fade" mode="out-in" appear>
                                <template v-if="nav_button_is_show == true">
                                    <div
                                        class="title-color-hide Transition-03s"
                                    >
                                        {{ item.title }}
                                    </div>
                                </template>
                                <template v-else>
                                    <div
                                        class="title-color-black Transition-03s"
                                    >
                                        {{ item.title }}
                                    </div>
                                </template>
                            </transition>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
export default {
    name: 'Header',
    props: {
        msg: String
    },
    created() {
        if (document.body.clientWidth > 850) {
            this.nav_is_show = false;
            this.nav_button_is_show = false;
        } else {
            this.nav_is_show = false;
            this.nav_button_is_show = true;
        }
    },
    mounted() {
        if (this.screenWidth < 800) {
            // that.topImgShow = false
            console.log('小尺寸');

            // var narbarButton=document.querySelector('.ComponentsHeader')
            $('.ComponentsHeader').addClass('navbar');
        } else {
            // that.topImgShow = true
            $('.ComponentsHeader').attr('class', 'ComponentsHeader');
            $('.nav-main').attr('class', 'nav-main');
        }
        const that = this;
        window.onresize = () => {
            return (() => {
                window.screenWidth = document.body.clientWidth;
                that.screenWidth = window.screenWidth;
                if (that.screenWidth < 800) {
                    // that.topImgShow = false
                    console.log(that.screenWidth,'小尺寸');

                    // var narbarButton=document.querySelector('.ComponentsHeader')
                    $('.ComponentsHeader').addClass('navbar');
                    //侧边按钮伸缩
                    $(".nav-main").addClass("navbar-collapse");
                    $(".nav-main").addClass("collapse");
                } else {
                    // that.topImgShow = true
                    $('.ComponentsHeader').attr('class', 'ComponentsHeader');

                    $(".nav-main").attr("class", "nav-main");
                }
            })();
        };
    },
    watch: {
        screenWidth(val) {
            // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
            if (!this.timer) {
                // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
                this.screenWidth = val;
                this.timer = true;
                let that = this;
                setTimeout(function () {
                    // 打印screenWidth变化的值
                    if (that.screenWidth < 850) {
                        that.nav_is_show = false;
                        that.nav_button_is_show = true;
                    } else {
                        that.nav_is_show = false;
                        that.nav_button_is_show = false;
                    }
                    console.log(that.screenWidth);
                    that.timer = false;
                }, 400);
            }
        }
    },
    methods: {
        oepnnav() {
            if (this.nav_button_is_show == true) {
                if (this.nav_is_show == false) {
                    this.nav_is_show = false;
                } else {
                    this.nav_is_show = false;
                }
            }
        },
        closenav() {
            this.nav_is_show = false;
        }
    },
    data() {
        return {
            nav_item: [
                {
                    title: '首页',
                    toname: 'home'
                },
                {
                    title: '产品',
                    toname: 'product'
                },
                {
                    title: '解决方案',
                    toname: 'solution'
                },
                {
                    title: '公司介绍',
                    toname: 'company'
                },
                {
                    title: '新闻动态',
                    toname: 'dynamic'
                },
                {
                    title: '招贤纳士',
                    toname: 'careers'
                }
            ],
            screenWidth: document.body.clientWidth,
            nav_is_show: false,
            nav_button_is_show: false
        };
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
img {
    image-rendering: -webkit-optimize-contrast;
}
.ComponentsHeader {
    position: fixed;
    width: 100%;
    z-index: 1500;
    background-color: #ffffff;

    .openbutton {
        visibility: hidden;

        margin-right: 30px;

        position: absolute;

        top: 300px;
        z-index: 1500;
    }

    .main {
        height: 80px;
    }
    .logo {
        float: left;
        line-height: 60px;
        height: 100px;
        overflow: hidden;
        .img {
            margin-top: 38px;
            // 提高图片清晰度

            // image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
        }
    }
    .nav-main {
        float: right;
        .nav-item {
            padding-right: 20px;
            float: left;
            line-height: 100px;
            font-size: 16px;
            .text {
                color: #0b314f;
                text-decoration: none;
            }
        }

        .router-link-active {
            border-bottom: 3px solid #0b314f;
            display: flow-root;
            .title-color-black {
                color: #38acff;
            }
        }

        .title-color-hide {
            color: #ffffff;
        }

        .title-color-hide:hover {
            color: #40b0ff;
        }

        .title-color-black {
            color: #0b314f;
        }

        .title-color-black:hover {
            color: #40b0ff;
        }
    }
}

@media screen and (max-width: 850px) {
    .ComponentsHeader {
        .openbutton {
            visibility: inherit;
            width: 30px;
            height: 30px;
            position: absolute;
            right: 25px;
            top: 25px;
            z-index: 1500;

            img {
                width: 100%;
                // 提高图片清晰度
                // image-rendering: -webkit-optimize-contrast;
            }
        }

        .main {
            height: 80px;
        }
        .logo {
            float: left;
            line-height: 80px;
            height: 80px;
            overflow: hidden;
            .img {
                margin-top: -5px;
                // 提高图片清晰度
                //  image-rendering: -webkit-optimize-contrast;
            }
        }

        .nav-main {
            background-color: #333333d4;
            width: 100%;
            position: fixed;
            left: 0;
            right: 0;
            top: 80px;
            // height: calc(100vh - 80px);
            z-index: 1500;

            .nav-item {
                width: 100%;
                margin-left: 0 !important;
                padding-left: 40px;
                padding-right: 0px;
                border-bottom: 1px solid #b6b6b6;
            }

            .router-link-active {
                border-bottom: 0px solid #0b314f;
                display: flow-root;
                .title-color-black {
                    color: #38acff;
                }
            }
        }
    }
}
</style>
