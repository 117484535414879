import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/Home/Home.vue'),
        meta: {title:'(中国)官方网站'}
      },
      {
        path: 'product',
        name: 'product',
        component: () => import('@/views/Partition/Product.vue'),
        meta: {title:'产品'}
      },
      {
        path: 'solution',
        name: 'solution',
        component: () => import('@/views/Partition/Solution.vue'),
        meta: {title:'解决方案'}
      },
      {
        path: 'company',
        name: 'company',
        component: () => import('@/views/Partition/Company.vue'),
        meta: {title:'公司介绍'}
      },
      {
        path: 'dynamic',
        name: 'dynamic',
        component: () => import('@/views/Partition/Dynamic.vue'),
        meta: {title:'动态'}
      },
      {
        path: 'careers',
        name: 'careers',
        component: () => import('@/views/Partition/Careers.vue'),
        meta: {title:'招贤纳士'}
      },
      {
        path: 'edit',
        name: 'home',
        component: () => import('@/views/Article.vue'),
        meta: {title:'静态文章生成工具'}
      },
    ]
  },
  {
    path: '/article/:id',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'article',
        component: () => import('@/views/Child/article.vue'),
        meta: {title:'文章'}
      }
    ]
  },
  {
    path: '/product/:id',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'product',
        component: () => import('@/views/Child/product.vue'),
        meta: {title:'文章'}
      }
    ]
  },
  {
    path: '/solution/:id',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'solution',
        component: () => import('@/views/Child/solution.vue'),
        meta: {title:'文章'}
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
