<template>
  <section class="LayoutMain">
    <!-- <transition
      name="fade-transform"
      mode="out-in"
    > -->
      <router-view ></router-view>
    <!-- </transition> -->
  </section>
</template>

<script>
export default {
  name: 'LayoutMain',
  computed: {
    // key() {
    //   return
    //   // return this.$route.name !== undefined ? this.$route.name + +new Date() : this.$route + +new Date()
    // }
  }
}
</script>

<style scoped>
.LayoutMain {
  /*50 = navbar  */
  min-height: calc(100vh - 450px);
  width: 100%;
  margin-top:100px;
}
@media screen and (max-width: 850px) {
.LayoutMain {
  /*50 = navbar  */
  min-height: calc(100vh - 80px);
  width: 100%;
  margin-top:80px;
}
}
</style>
